export default {
    namespaced: true,

    state: () => {
        return {
            loadingColors: false,
            colors: [],
        }
    },

    getters: {
        loadingColors(state) {
            return state.loadingColors;
        },

        colors(state) {
            return state.colors.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
    },

    mutations: {
        loadingColors(state, isLoading) {
            state.loadingColors = isLoading;
        },

        setColor(state, colors) {
            state.colors = colors;
        },
    },

    actions: {
        async getColors(context) {
            context.commit('loadingColors', true);

            let colors = await axios.get(`${this.state.host}/get-colors`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.colors;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })

            context.commit('setColor', colors);

            context.commit('loadingColors', false);
        },

        async saveColor(context, color) {
            let isSuccess = false;

            let url = 'add-color';

            if(color.id != null) {
                url = 'update-color';
            }

            await axios.post(`${this.state.host}/${url}`, color, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                context.dispatch('getColors');
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            });

            return isSuccess;
        },

        async deleteColor(context, id) {
            await axios.delete(`${this.state.host}/delete-color/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.dispatch('getColors');
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            });
        }
    }
}
