export default {
    namespaced: true,

    state: () => {
        return {
            loadingSubCategories: false,
            subCategories: [],
        }
    },

    getters: {
        loadingSubCategories(state) {
            return state.loadingSubCategories;
        },

        subCategories(state) {
            return state.subCategories.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
    },

    mutations: {
        loadingSubCategories(state, isLoading) {
            state.loadingSubCategories = isLoading;
        },

        setSubCategory(state, categories) {
            state.subCategories = categories;
        },
    },

    actions: {
        async getSubCategories(context) {
            context.commit('loadingSubCategories', true);

            let categories = await axios.get(`${this.state.host}/get-sub-categories`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.categories;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })

            context.commit('setSubCategory', categories);

            context.commit('loadingSubCategories', false);
        },

        async saveSubCategory(context, category) {
            let isSuccess = false;

            let url = 'add-sub-category';

            if(category.id != null) {
                url = 'update-sub-category';
            }

            await axios.post(`${this.state.host}/${url}`, category, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                context.dispatch('getSubCategories');
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            });

            return isSuccess;
        },

        async deleteSubCategory(context, id) {
            await axios.delete(`${this.state.host}/delete-sub-category/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.dispatch('getSubCategories');
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            });
        }
    }
}
