export default {
    namespaced: true,

    state: () => {
        return {
            loadingVariants: false,
            variants: [],
        }
    },

    getters: {
        loadingVariants(state) {
            return state.loadingVariants;
        },

        variants(state) {
            return state.variants.map((item, sl) => {
                item.display_text = `${item.product.name} - ${item.color.name} - ${item.size.name}`;
                item.sl = sl + 1;
                return item;
            });
        },
    },

    mutations: {
        loadingVariants(state, isLoading) {
            state.loadingVariants = isLoading;
        },

        setVariant(state, variants) {
            state.variants = variants;
        },
    },

    actions: {
        async getVariants(context, payload) {
            context.commit('loadingVariants', true);

            let variants = await axios.post(`${this.state.host}/get-variants`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.variant;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })

            context.commit('setVariant', variants);

            context.commit('loadingVariants', false);
        },

        async saveVariant(context, variant) {
            let isSuccess = false;

            let url = 'add-variant';

            if(variant.id != null) {
                url = 'update-variant';
            }

            await axios.post(`${this.state.host}/${url}`, variant, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                context.dispatch('getVariants');
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            });

            return isSuccess;
        },

        async deleteVariant(context, id) {
            await axios.delete(`${this.state.host}/delete-variant/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.dispatch('getVariants');
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            });
        }
    }
}
