export default {
    namespaced: true,

    state: () => {
        return {
            loadingSizes: false,
            sizes: [],
        }
    },

    getters: {
        loadingSizes(state) {
            return state.loadingSizes;
        },

        sizes(state) {
            return state.sizes.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
    },

    mutations: {
        loadingSizes(state, isLoading) {
            state.loadingSizes = isLoading;
        },

        setSize(state, sizes) {
            state.sizes = sizes;
        },
    },

    actions: {
        async getSizes(context) {
            context.commit('loadingSizes', true);

            let sizes = await axios.get(`${this.state.host}/get-sizes`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.sizes;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })

            context.commit('setSize', sizes);

            context.commit('loadingSizes', false);
        },

        async saveSize(context, size) {
            let isSuccess = false;

            let url = 'add-size';

            if(size.id != null) {
                url = 'update-size';
            }

            await axios.post(`${this.state.host}/${url}`, size, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                context.dispatch('getSizes');
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            });

            return isSuccess;
        },

        async deleteSize(context, id) {
            await axios.delete(`${this.state.host}/delete-size/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.dispatch('getSizes');
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            });
        }
    }
}
